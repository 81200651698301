import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function UploadPdfPage() {
  const [file, setFile] = useState(null);
  const inputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  
  const handleFileClick = (file) => {
    setSelectedFile(file);
   setPageNumber(1);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const openPdf=(fileName)=>{
    const url = `${process.env.REACT_APP_API_URL}/read?fileName=a.pdf`;
    window.open(url, '_blank');
  }
  const handleDownloadClick = async () => {
    setLoading(true);
    try {
     
      const fileName = selectedFile.fileName.split('/').pop();
     const response = await axios.get(`${process.env.REACT_APP_API_URL}/download/a.pdf`, {
        responseType: 'blob',
      }, {
        headers: {
            "x-access-token" : sessionStorage.getItem("x-access-token"),
        },
      } );
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'example.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUploadClick = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('pdf', file);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload-pdf`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          "x-access-token": sessionStorage.getItem("x-access-token")
        },});
        setFiles([...files, response.data]);
        // console.log(files)
        setLoading(false);
      console.log('File uploaded successfully:', response.data);
    } catch (error) {
      console.error('Error uploading file:', error);
      setLoading(false);
    }
  };

  const handleButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} style={{ display: 'none' }} ref={inputRef} />
      <button onClick={handleButtonClick}>Select File</button>
      <button onClick={handleUploadClick} disabled={!file}>Upload</button>
      <button onClick={handleDownloadClick}>download</button>
      <button onClick={openPdf}>open</button>
      

      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <h2>Uploaded files:</h2>
          <ul>
            {files.map((file) => (
              <button key={file.id} onClick={() => handleFileClick(file)}>
                {file.fileName} 

              </button>
            ))}
          </ul>
        </div>
      )}

      {selectedFile && (
        <div>

           <Document file={`${process.env.REACT_APP_API_URL}/upload/${selectedFile.fileName.path}`} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
            {console.log(selectedFile)}
            {console.log("selectedFile")}
          </Document>
          <p>
            Page {pageNumber} of {numPages}
          </p>
          <button
            disabled={pageNumber <= 1}
            onClick={() => setPageNumber(pageNumber - 1)}
          >
            Previous
          </button>
          <button
            disabled={pageNumber >= numPages}
            onClick={() => setPageNumber(pageNumber + 1)}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
}

export default UploadPdfPage;
