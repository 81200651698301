import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/iconify';

export default function LoginForm() {
  const navigate = useNavigate();
  const [Email, setEmail] = useState("");
  const [Pass, setPass] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = {
    "email": Email,
    "password": Pass
  }
  
  const handleClick = async () => {
    setLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/Login`, user);
      if (response.data.rc >= 0) {
        sessionStorage.setItem('x-access-token', response.data.token)
        sessionStorage.setItem('photoURL', response.data.user.ImageUrl)
        sessionStorage.setItem('displayName', response.data.user.FullName)
        sessionStorage.setItem('email', response.data.user.email)
        sessionStorage.setItem('admin', response.data.user.admin)
        sessionStorage.setItem('plan', response.data.user.Plan)
       setTimeout(() => {
                navigate('/dashboard', { replace: true }, { value: true });
                setLoading(false)

       }, 200);

        
      } else {
        console.log(response.data.rc);
        setLoading(false)
        if (response.data.rc === "Invalid email") {
          alert("Please check your email.");
        } else {
          alert("Please check your password.");
        }
      }
    } catch (err) {
      setLoading(false)
      console.log(err);
    }
  }
    // Simulate backend loading time with a setTimeout
  

  return (
    <>
     
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center", padding: "25%", textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <Stack spacing={3}>
            <TextField name="email" label="Email address" onChange={e => setEmail(e.target.value)} />

            <TextField
              name="password"
              label="Password"
              onChange={e => setPass(e.target.value)}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        )}

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Checkbox name="remember" label="Remember me" />
          <Link variant="subtitle2" underline="hover">
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
          Login
        </LoadingButton>
      
    </>
  );
}
