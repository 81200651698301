import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Link, CircularProgress } from '@mui/material';

// components
import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import Iconify from '../components/iconify';

// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import ConvRate from "./jsonFiles/ConverRate.json"
import WebVisite from "./jsonFiles/WebVisite.json"

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const navigate = useNavigate();
const[totalPdf, settotalPdf] = useState(0)
const[UserData, setUserData] = useState(0)
const[loading, setLoading] = useState(true)

  // getting data to display 


  useEffect(()=>{
    setTimeout(() => {
          Data();
    }, 500);
  },[])
    const Data = async() => {
    
    // user list here
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/userList`,
    {
         headers: {
      "x-access-token": sessionStorage.getItem("x-access-token")
    }
      
  } 
  
  ).then((res) => {
    

      if (res.data.rc >= 0) {
  
setUserData([...res.data.UserData])
    // setIsLoaded(true)

      }
      else {
        // console.log(res.data.rc)

      }
    })

    // pdf list 
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/PdfsList`,
          { 
            headers: {
          "x-access-token": sessionStorage.getItem("x-access-token")
      } 
    }
      
      ).then((res) => {
          if (res.data.rc >= 0) {
           
    settotalPdf([...res.data.pdfs])

          }
          else {
            console.log(res.data.rc)
    
          }
        })
        setLoading(false)

      }
      catch (err) {
        setLoading(false)

        console.log(err)
      }
     
    };
    
   
  

    // ends data here
  return (
    <>
      <Helmet>
        <title> Dashboard | Minimal UI </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>
{loading? <div style={{display: "flex", justifyContent: "center",padding: "25%", textAlign: "center"}}><CircularProgress/> </div>  :
        <Grid container spacing={3}>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total User" total={UserData.length} icon={'mdi:user-group'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="New Users" total={4} color="info" icon={'mdi:user-circle'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total PDF" total={totalPdf.length} color="warning" icon={'ant-design:file-pdf-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Your Plan" total={sessionStorage.getItem('plan')} color= {sessionStorage.getItem('plan') === 'Free' ? "error" : "primary"}  icon={'ant-design:dollar-circle-filled'} />
          </Grid>
   

          <Grid style={{ position: 'relative' }} item xs={12} md={6} lg={8}>
  <AppWebsiteVisits
  style={{filter: sessionStorage.getItem('plan') === 'Free' ? 'blur(19px)' : 'blur(0px)' }}
    title="Your Progress"
    subheader=""
    chartLabels={[ 'Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7', 'Day 8', 'Day 9', 'Day 10', 'Day 11', ]}
    chartData={WebVisite}
  />
 <UpgradToPaid/>
</Grid>




          <Grid item xs={12} md={6} lg={4} style={{ position: 'relative' }}>

            <AppCurrentVisits
              style={{filter: sessionStorage.getItem('plan') === 'Free' ? 'blur(19px)' : 'blur(0px)' }}

              title="Points"
              chartData={[
                { label: 'Points', value: 2000 },
                { label: 'Gain More', value: 8000 },

              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                // theme.palette.warning.main,
                // theme.palette.error.main,
              ]}
            />
<UpgradToPaid/>

          </Grid>

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Your Consistancy"
              subheader=""
              chartData={ConvRate}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Your Duration"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '6 hour',
                  '12 hour',
                  '3 hour',
                  '3 hour',
                  '63 hour',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>}
      </Container>
      <Footer/>
    </>
  );
}

function UpgradToPaid(){
  const navigate = useNavigate();

  return(
    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', zIndex: 1 }}>
    {sessionStorage.getItem('plan') === 'Free' && (
      <>
        <h2 style={{ textAlign: 'center' }}>To access this feature, please upgrade to the paid version.</h2>
        <button style={{ padding: '10px', borderRadius: '5px' }} onClick={()=>navigate("/PaymentPage")}>Upgrade</button>
      </>
    )}
  </div>
  )
}



// Footer pages



export const Footer = () => {
const navigate = useNavigate()
  return (
    <footer style={{margin: "4% 0px 0px 4%"}}>
      <Grid item xs={12} style ={{    borderRadius: '15px 15px',
backgroundColor: "#c0eded", display: "flex", justifyContent: "space-between", padding: "7px" }} container spacing={4} justify="space-between">
        <Grid item xs={12} sm={6} md={3} style={{margin: "1%"}}>
          <Typography variant="h6" color="textPrimary" gutterBottom>
            Contact Us
          </Typography>
          
          <Link onClick={()=>navigate("/ContactUs")} variant="body2" color="textSecondary">
           Contact Us
          </Link>
       
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" color="textPrimary" gutterBottom>
            Privacy Policy
          </Typography>
          <Link onClick={()=>navigate("/PrivacyPolicy")} variant="body2" color="textSecondary">
            Read our privacy policy
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" color="textPrimary" gutterBottom>
            Links
          </Typography>
          <Link onClick={()=>navigate("/AboutUs")} variant="body2" color="textSecondary">
            About Us
          </Link>
          <br />
          <Link onClick={()=>navigate("/TermsConditions")} variant="body2" color="textSecondary">
            Terms & Conditions
          </Link><br/>
          <Link onClick={()=>navigate("/Refund")} variant="body2" color="textSecondary">
          Refund & Cancellation Policy
          </Link><br/>
          {/* <Link onClick={()=>navigate("/PrivacyPolicy")} variant="body2" color="textSecondary">
            Disclaimer
          </Link> */}
        </Grid>
      </Grid>
    </footer>
  );
};


