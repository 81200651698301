import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// @mui
import { Container, Stack, Typography, Button, CircularProgress } from '@mui/material';
import Iconify from '../components/iconify';

// components
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';

// ----------------------------------------------------------------------

export default function ProductsPage() {
  const [openFilter, setOpenFilter] = useState(false);
  const [PRODUCTS, setPRODUCTS] = useState([]);
  const [loading, setLoading] = useState(true); // state for loading status
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      Data();
    }, 500); // Wait for 2 seconds before fetching data
  }, []);


  const Data = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/Categorieslist`,
        {
          headers: {
            'x-access-token': sessionStorage.getItem('x-access-token'),
          },
        }
      );
      if (response.data.rc >= 0) {
        setPRODUCTS([...response.data.category]);
      } else {
        // console.log(res.data.rc)
      }
      setLoading(false); // update loading status
    } catch (err) {
      console.log(err);
      setLoading(false); // update loading status
    }
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  return (
    <>
      <Helmet>
        <title> Quiz </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Quiz Category
        </Typography>
        {sessionStorage.getItem('admin') === 'true' && (
          <Button
            variant="contained"
            onClick={() => {
              navigate('/addques', { replace: true }, { value: true });
            }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add questions
          </Button>
        )}

        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 5 }}
        >
          {/* <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <ProductFilterSidebar
              openFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <ProductSort />
          </Stack> */}
        </Stack>

        {/* Show loader while data is loading */}
        {loading ? (
          <Stack alignItems="center" justifyContent="center" sx={{ height: '80vh' }}>
            <CircularProgress />
          </Stack>
        ) : (
          <ProductList products={PRODUCTS} />
        )}

        {/* <ProductCartWidget /> */}
      </Container>
    </>
  );
}
