import PropTypes from 'prop-types';
// @mui
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import {useState, useEffect } from 'react'
import { Box, Card, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};


export default function ShopProductCard({ product }) {
  const navigate = useNavigate();
  const [Question, setQuestion] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
  
  


  const { id, categoryName, categoryImage } = product;
  
  function navigateToQuiz() {
    const Data = async() => {
      // dotenv.config();

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/1/questions`,
        {
             headers: {
          "x-access-token": sessionStorage.getItem("x-access-token")
        }
          
      } 
      
      ).then((res) => {
          if (res.data.rc >= 0) {
           
    setQuestion([...res.data.question])
   
        setIsLoaded(true)

          }
          else {
            console.log(res.data.rc)
    
          }
        })
      }
      catch (err) {
    
        console.log(err)
      }
     
    };
  Data();
    navigate(`/dashboard/quiz/${id}`);


  }
  return (
    <Card onClick={() => { navigateToQuiz() }} sx={{
      position: 'relative',
      ...(sessionStorage.getItem('plan') === 'Free' && {
        filter: id === 1 ? 'none' : 'blur(4px)',
        pointerEvents: id === 1 ? 'auto' : 'none',
      }),
    
     }}>

      <Box sx={{ pt: '100%', position: 'relative' }}>
        {/* {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {status}
          </Label>
        )} */}
        <StyledProductImg alt={categoryName} src={categoryImage} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link color="inherit" underline="hover">
          <Typography variant="subtitle2" noWrap>
            {categoryName}
          </Typography>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {/* <ColorPreview colors={colors} /> */}
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',
                textDecoration: 'line-through',
              }}
            >
              {/* {priceSale && fCurrency(priceSale)} */}
            </Typography>
            &nbsp;
            {/* {Math.floor(price)} */}
           40  questions
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
