import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: "3%"}}>
<h1>Privacy Policy for CCC Quiz and Study Material App</h1>
<p>At CCC Quiz, we are committed to respecting and protecting your privacy. This privacy policy explains how we collect, use, and protect your personal information when you use our CCC Quiz and Study Material app.</p>
<h2>Information We Collect</h2>
<p>We collect information you provide to us when you use our app, such as your name, email address, the `order_id` and `payment_id` associated with your transaction. We also collect information about your usage of the app, such as your quiz results and the study material you access.</p>
<h2>How We Use Your Information</h2>
<p>We use your personal information to provide you with access to the app's features and to improve our app's performance. We may also use your information to communicate with you about updates to the app and other relevant information.</p>
<p>We may use your information to personalize your experience in the app by suggesting study material and quizzes that match your interests and performance.</p>
<p>We use your payment information, including the `order_id` and `payment_id`, to process your payment for access to the app's features and to verify the status of your payment. We store this information on our servers in accordance with industry-standard security practices.</p>
<h2>How We Share Your Information</h2>
<p>We may share your personal information with third-party service providers who assist us in providing the app's features, such as payment processors and hosting providers.</p>
<p>We may disclose your information to law enforcement or government officials if required by law or in response to a legal request.</p>
<h2>Data Security</h2>
<p>We take appropriate measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. We use industry-standard encryption to protect your payment information, including the `order_id` and `payment_id`, during transmission and storage.</p>
<p>However, no method of transmission over the internet or electronic storage is completely secure. We cannot guarantee the security of your personal information, including the `order_id` and `payment_id`.</p>
<h2>Access and Control of Your Information</h2>
<p>You may access and update your personal information, in the app at any time. You may also request that we delete your personal information from our systems by contacting us at cccquiz@silentrend.com. We will comply with your request unless we are required by law to retain your information.</p>
<h2>Changes to this Policy</h2>
<p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on our website and within the app. We recommend that you review this policy periodically.</p>
<h2>Contact Us</h2>
<p>If you have any questions or concerns about this privacy policy please contact us at cccquiz@silentrend.com.</p>
   </div>
  )
}

export default PrivacyPolicy;