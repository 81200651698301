import React, { useState, useEffect } from 'react'
import { Button, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';


import axios from 'axios'
import './QuizScreen.css'

export default function QuizScreen() {
    const [Question, setQuestion] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [LoadAgain, setLoadAgain] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [score, setScore] = useState(0);
    const formap =  ['a','d','c','d'];
    const params = useParams();

    // for back button
    const goBack =()=> {
        return window.history.back()   

    }
    // for load the data
    useEffect(()=>{
        const Data = async() => {
            // dotenv.config();
            setIsLoaded(true)

            try {
              const response = await axios.get(`${process.env.REACT_APP_API_URL}/${params.id}/questions`,
              {
                   headers: {
                "x-access-token": sessionStorage.getItem("x-access-token")
              }
                
            } 
            
            ).then((res) => {
                if (res.data.rc >= 0) {
                 
          setQuestion([...res.data.question])
         
              setIsLoaded(false)
  
                }
                else {
                    setIsLoaded(false)
                  console.log(res.data.rc)
          
                }
              })
            }
            catch (err) {
          setIsLoaded(false)
              console.log(err)
            }
           
          };
          setTimeout(() => {
                    Data();

          }, 200);
      },[])

      
       
      


    

    const handleAnswerOptionClick = (option) => {
       
        if (option.trim() === Question[currentQuestion].Answer.trim()) {
            setScore(score + 1);
        }

        const nextQuestion = currentQuestion + 1;
        if (nextQuestion < Question.length) {
            setCurrentQuestion(nextQuestion);
        } else {
            setShowScore(true);
        }
    };
    return (
        <div>
            <Button variant="contained" onClick ={()=> goBack()} >
                Back
               
            </Button>
{Question.length !== 0 ? 
            <div className='quiz_body'>

                <div className='quizapp'>
                    {showScore ? (
                        <div className='score-section'>
                            You scored {score} out of {Question.length}
                        </div>
                    ) : (
                        <>
                            <div className='question-section'>

                                <div className='question-count'>
                                    <span>Question {currentQuestion + 1}</span>/{Question.length}
                                </div>
                                <div className='question-text'>{Question[currentQuestion].question}</div>
                            </div>
                            <div className='answer-section'>
                                    <button className='button-quiz' onClick={() => handleAnswerOptionClick(Question[currentQuestion].option1)}>{Question[currentQuestion].option1}</button>
                                   <br/> <button  className='button-quiz' onClick={() => handleAnswerOptionClick(Question[currentQuestion].option2)}>{Question[currentQuestion].option2}</button>
                                   <br/>  <button  className='button-quiz' onClick={() => handleAnswerOptionClick(Question[currentQuestion].option3)}>{Question[currentQuestion].option3}</button>
                                   <br/>     <button  className='button-quiz' onClick={() => handleAnswerOptionClick(Question[currentQuestion].option4)}>{Question[currentQuestion].option4}</button>
                             
                            </div>
                          </>

                    )}
                    
                </div>
               


<div className='question-tracker'>
  {Question.map((q, index) => (
    <button
      key={index}
      className={`question-number ${index === currentQuestion ? 'current-question' : ''}`}
      onClick={() => setCurrentQuestion(index)}
    >
      {index + 1}
    </button>
  ))}
</div>
            </div> : <div><CircularProgress/></div>}

                


        </div>
    );
}
