import React from 'react'

const ContactUs = () => {
  return (
    <div style={{ padding: "3%"}}>

<h1>ContactUs</h1>
  <p>Email: cccquiz@silentrend.com</p>
  {/* <p>email 2: anoopchandra671@gmail.com</p> */}
 <p> Operational Address: City: Mangal Bazar Rd, Sonari Mohalla, Bansi, Uttar Pradesh 272153</p>
    </div>
  )
}

export default ContactUs