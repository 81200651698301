import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import './PaymentPage.css';

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

const __DEV__ = document.domain === 'localhost';

export async function displayRazorpay(setIsLoading) {
    setIsLoading(true);
    console.log("hered")
    console.log(process.env.ROZERPAY_PRODUCTION_KEY)
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

    if (!res) {
        alert('Razorpay SDK failed to load. Are you online?');
        setIsLoading(false);
        return;
    }

    const data = await fetch(`${process.env.REACT_APP_API_URL}/razorpay`, { method: 'POST' },
    {
        headers: {
                
             "x-access-token": sessionStorage.getItem("x-access-token")       
          }
    }).then((t) =>
        t.json()
    );
  
    const options = {
        key: "rzp_test_LqHPOEWQm2UzQH",
        currency: data.currency,
        amount: 2900,
        order_id: data.id,
        name: 'CCC Fee Payment',
        description: 'Thank you for purchase the paid plan',
        image: 'https://silentrend.com/wp-content/uploads/2023/03/Black-And-White-Modern-Vintage-Retro-Brand-Logo.png',
        handler(response) {
            console.log('payment done 🙂');
        //   alert(response.razorpay_order_id)
          handleUpdatePlan(response.razorpay_order_id, response.razorpay_payment_id);
            window.location.href = '/';
        },
        prefill: {
			name: sessionStorage.getItem("displayName"),
			email: sessionStorage.getItem("email")
        		}
    };
    const paymentObject = new window.Razorpay(options);

    paymentObject.open();
    setIsLoading(false);
}

const ConfirmNavigate = ({ message, path }) => {
    const navigate = useNavigate();
    const confirmed = window.confirm(message);

    navigate(path);
    return null;
};


const handleUpdatePlan = async (OrderId, PaymentId) => {
	try {
	  const response = await axios.put(`${process.env.REACT_APP_API_URL}/userPlan`, { email: sessionStorage.getItem('email'), Plan: "Paid", Order_id: OrderId, Payment_id : PaymentId}
	  , {
		headers: {
			"x-access-token" : sessionStorage.getItem("x-access-token"),
		},
	  } );
	 
	  const confirmed = window.confirm("Your payment is done. Click OK and Login with same email and password.");
	

	} catch (error) {
	  console.error(error);
	}
  };


  // UI Part starts from here
export default function PaymentPage() {
	const [name, setName] = useState("anoop")
	const [isLoading, setIsLoading] = useState(false)
const navigate = useNavigate()


	return (
		<div className="Main">
			{isLoading ? (
    <CircularProgress />) : (
		<>
    <button className="primary-button" onClick={() => displayRazorpay(setIsLoading)} target="_blank">
    Pay Fee
</button>
      <button className="secondary-button" onClick={()=>{  navigate('/dashboard', { replace: true },{ value: true })}}>Go to Dashboard</button> 
	 

				</> )}
			
		</div>
	)
}

