import React from 'react'

const TermsConditions = () => {
  return (
    <div style={{padding: "3px"}}>
        <h1>Terms and Conditions</h1>
<p>Please read these Terms and Conditions carefully before using the CCC Quiz and Study Material App.</p>
<h2>Acceptance of Terms</h2>
<p>By accessing and using this app, you agree to be bound by these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use our app.</p>
<h2>Use of App</h2>
<p>The CCC Quiz and Study Material App is intended to be used for educational purposes only. We do not guarantee that using our app will result in passing the CCC exam.</p>
<p>The use of our app is strictly limited to individuals who have paid the subscription fee of 29 rupees. Sharing your account information with others is strictly prohibited and may result in termination of your account.</p>
<h2>Intellectual Property</h2>
<p>All content and materials provided in the CCC Quiz and Study Material App, including quizzes, study materials, and other resources, are the property of our app and are protected by intellectual property laws.</p>
<p>Unauthorized use, reproduction, or distribution of our content is strictly prohibited.</p>
<h2>Limitation of Liability</h2>
<p>Our app is provided on an "as is" and "as available" basis. We do not guarantee that our app will be uninterrupted, error-free, or virus-free.</p>
<p>We are not liable for any direct, indirect, incidental, consequential, or special damages that result from the use of, or inability to use, our app.</p>
<h2>Privacy</h2>
<p>We take your privacy seriously and will only collect and use your personal information in accordance with our Privacy Policy, which can be found on our website and within the app.</p>
<h2>Changes to Terms and Conditions</h2>
<p>We may update these Terms and Conditions from time to time without notice. Your continued use of the app after any changes to these Terms and Conditions indicates your acceptance of the updated Terms and Conditions.</p>
<h2>Contact Us</h2>
<p>If you have any questions or concerns about these Terms and Conditions, please contact us at cccquiz@silentrend.com.</p>
    </div>
  )
}

export default TermsConditions