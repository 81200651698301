// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------



const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;



const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },

  // product = categories for all 
  {
    title: 'Quiz',
    path: '/dashboard/categories',
    icon: icon('ic_cart'),
  },
  // blog = PDF page (download and read)
  {
    title: 'Study Material',
    path: '/dashboard/pdf',
    icon: icon('ic_blog'),
  },
  // user = All user with points 
  {
    title: 'users',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },


];

export default navConfig;
