import React from 'react'

const AboutUs = () => {
  return (
    <div style={{padding: "3%"}}>

<h1>About Us</h1>
<p>Welcome to the CCC Quiz and Study Material App! Our app provides you with access to a wide range of study materials, quizzes, and practice exams that are designed to help you succeed on the CCC exam.</p>
<p>It's important to note that while we strive to provide the most accurate and up-to-date information possible, our app is not affiliated with the official CCC examination board. We do not guarantee that using our app will result in passing the CCC exam.</p>
<p>Our app is designed to be user-friendly and accessible to everyone, regardless of your level of expertise in computer technology. With our app, you can study at your own pace and track your progress as you prepare for the exam.</p>
<h2>Our Mission</h2>
<p>Our mission is to help individuals achieve their goals by providing them with the resources they need to succeed on the CCC exam. We believe that education should be accessible to everyone, regardless of their financial situation. That's why we offer our app at an affordable price of just 29 rupees.</p>




    </div>
  )
}

export default AboutUs