import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import BlogPage from './pages/BlogPage';
import UploadPdfPage from './pages/UploadPdfPage';
import PaymentPage from './pages/PaymentPage';
import PrivacyPolicy from './pages/FooterPages/PrivacyPolicy';
import ContactUs from './pages/FooterPages/ContactUs';
import Refund from './pages/FooterPages/Refund';
// import Disclaimer from './pages/FooterPages/Disclaimer';
import TermsConditions from './pages/FooterPages/TermsConditions';
import AboutUs from './pages/FooterPages/AboutUs';
import AddQuestions from './pages/AddQuestions';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import SigninPage from './pages/SigninPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import QuizScreen from './sections/@dashboard/products/quizScreen/QuizScreen';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
       
        { path: 'categories', element: <ProductsPage /> },
        { path: 'pdf', element: <BlogPage /> },
        { path: 'quiz/:id', element: <QuizScreen /> },
      ],
    },
    { path: 'addques', element: <AddQuestions /> },
    { path: 'uploadpdf', element: <UploadPdfPage /> },
    { path: 'PaymentPage', element: <PaymentPage /> },
    { path: 'PrivacyPolicy', element: <PrivacyPolicy /> },
    { path: 'ContactUs', element: <ContactUs /> },
    { path: 'Refund', element: <Refund /> },
    // { path: 'Disclaimer', element: <Disclaimer /> },
    { path: 'TermsConditions', element: <TermsConditions /> },
    { path: 'AboutUs', element: <AboutUs /> },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'Signin',
      element: <SigninPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
