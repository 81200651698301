import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AddQuestions = () => {

const [SingleScreen, setSingleScreen] = useState(true);

return (
    <>
    <button onClick={(e)=> setSingleScreen(false)}> Multiple question add</button>
    <button onClick={(e)=> setSingleScreen(true)}> Single question add</button>
    {SingleScreen ?
    <SingleAdd/>:
    <AddBulkQuestions/>}
    </>
)
}



export default AddQuestions




// bulk Questions
const AddBulkQuestions = () => {
    const [AddQuestions, setAddQuestions ] = useState([])
  
    const handleClick = async (e) => {
      console.log( AddQuestions )
  
      try {
        const data = AddQuestions.split('\n').map((line) => {
          const [question, option1, option2, option3, option4, Answer, fkCategoryId] = line.split(',');
          return {
            question,
            option1,
            option2,
            option3,
            option4,
            Answer,
            fk_category_id : fkCategoryId
          };
        });
  
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/AddBulkQuestion`, data, {
          headers: {
            "x-access-token" : sessionStorage.getItem("x-access-token"),
          },
        }).then((res) => {
          console.log("question added successfully")
          console.log(res.data)
        })
      } catch (err) {
        console.log(err)
        alert("please enter valid details ")
      }
    }
  
    return (
      <div style={{'marginTop': "30px","marginLeft":"15%", "width":"70vw"}}>
        <textarea
          placeholder="Enter data in csv format with every field and without any extra comma or error"
          onChange={(e) => setAddQuestions(e.target.value)}
          style={{ resize: "both", overflow: "auto", width: "100%", minHeight: "500px" }}
        />
  
        <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={(e)=>handleClick(e)}>
          Add Bulk
        </LoadingButton>
      </div>
    )
  }
  



// Single Screen


const SingleAdd = () => {
 
    const [Ques, setQues] = useState("");
    const [Data, setData] = useState("");
    const [Option1, setOption1] = useState("");
    const [Option2, setOption2] = useState("");
    const [Option3, setOption3] = useState("");
    const [Option4, setOption4] = useState("");
    const [Answer, setAnswer] = useState("");
    const [isChecked1, setIsChecked1] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [isChecked3, setIsChecked3] = useState(false);
    const [isChecked4, setIsChecked4] = useState(false);
    const [fkCategoryId, setfkCategoryId] = useState(1);
    const [fkQuestiongroupId, setfkQuestiongroupId] = useState(1);
    const [showCsvAdd, setShowCsvAdd] = useState(false); // add state variable to track which screen is visible

    // rest of the state variables and functions
  
    const handleShowCsvAdd = () => {
      setShowCsvAdd(true);
    };
  
    const handleShowSingleAdd = () => {
      setShowCsvAdd(false);
    };

    const handleCheckBox = (option,no,doneSign) => {
        setAnswer(option);
        
        localStorage.setItem('fk_category_id', fkCategoryId);
        localStorage.setItem('fk_question_group_id', fkQuestiongroupId);

        if(no === 1){
            setIsChecked1(true);
            setIsChecked2(false)
            setIsChecked3(false)
            setIsChecked4(false)

        }
        else if(no === 2){
            setIsChecked1(false);
            setIsChecked2(true)
            setIsChecked3(false)
            setIsChecked4(false)
        }
        else if(no === 3){
            setIsChecked1(false);
            setIsChecked2(false)
            setIsChecked3(true)
            setIsChecked4(false)        }
        else{
            setIsChecked1(false);
            setIsChecked2(false)
            setIsChecked3(false)
            setIsChecked4(true)        }
    }

  


    const Question = {
        Questiondata: {
            question: Ques,
            option1: Option1,
            option2: Option2,
            option3: Option3,
            option4: Option4,
            Answer,
            fk_category_id: fkCategoryId,
            fk_category_group_id: fkQuestiongroupId
        }
    }

    const handleClick = async(e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/AddQuestion`, Question, {
                headers: {
                    "x-access-token" : sessionStorage.getItem("x-access-token"),
                },
            }).then((res) => {
                // console.log(res.data)
            })
         
        }
        catch (err) {
            console.log(err)
            alert("please enter valid details ")
        }
    };
    const handleClickforCsv = async(e) => {

      const data = Data.split(',');

      // set the state variables from the array
    
      const Question2 = {
        Questiondata: {
            question: data[0],
            option1: data[1],
            option2: data[2],
            option3: data[3],
            option4: data[4],
            Answer: data[5],
            fk_category_id: fkCategoryId,
            fk_category_group_id: fkQuestiongroupId
        }
    }


        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/AddQuestion`, Question2, {
                headers: {
                    "x-access-token" : sessionStorage.getItem("x-access-token"),
                },
            }).then((res) => {
                console.log(res.data)

              
            })
         
        }
        catch (err) {
            console.log(err)
            alert("please enter valid details ")
        }
    };

    return (
      <div style={{ marginTop: '30px', marginLeft: '15%', width: '70vw' }}>
      {showCsvAdd ? (
        // render CSV add screen
        <div>
          <Stack spacing={3}>

          <textarea
          placeholder="Enter data in csv format with every field and without any extra comma or error"
          onChange={(e) => setData(e.target.value)}
          style={{ resize: "both", overflow: "auto", width: "100%", minHeight: "100px" }}
        />


          </Stack>
          <br/>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={(e) => handleClickforCsv(e)}>
            Add
          </LoadingButton>
        </div>
      ) : (
        // render single add screen
        <div>
          <Stack spacing={3}>
            <TextField name="Question" label="Question" onChange={(e) => setQues(e.target.value)} />
          </Stack>
          <div style={{ marginTop: '15px' }} />

          <TextField style={{ width: '40vw' }} name="Option1" label="Option1" onChange={(e) => setOption1(e.target.value)} />
          <Checkbox checked={isChecked1} onChange={() => handleCheckBox(Option1, 1, isChecked1)} />
          <div style={{ marginTop: '15px' }} />

          <TextField style={{ width: '40vw' }} name="Option2" label="Option2" onChange={(e) => setOption2(e.target.value)} />
          <Checkbox checked={isChecked2} onChange={() => handleCheckBox(Option2, 2, isChecked2)} />
          <div style={{ marginTop: '15px' }} />

          <TextField style={{ width: '40vw' }} name="Option3" label="Option3" onChange={(e) => setOption3(e.target.value)} />
          <Checkbox checked={isChecked3} onChange={() => handleCheckBox(Option3, 3, isChecked3)} />
          <div style={{ marginTop: '15px' }} />

          <TextField style={{ width: '40vw' }} name="Option4" label="Option4" onChange={(e) => setOption4(e.target.value)} />
          <Checkbox checked={isChecked4} onChange={() => handleCheckBox(Option4, 4, isChecked4)} />

          <div style={{ marginTop: '15px' }} />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={(e) => handleClick(e)}>
            Add
          </LoadingButton>
         
        </div>
      )}
    
            <div style={{"marginTop": "25px"}}/>

            <TextField defaultValue={localStorage.getItem('fk_category_id')} name="fk_Category" label="fk_Category" onChange={e =>{ 
                setfkCategoryId(e.target.value)
         localStorage.setItem('fk_category_id', e.target.value)}
        }/>
            <TextField defaultValue={localStorage.getItem('fk_question_group_id')} name="fk_Question_Group_id" label="fk_Question_Group_id" onChange={e => {
                setfkQuestiongroupId(e.target.value)
        localStorage.setItem('fk_question_group_id', e.target.value)}
     }/>
                 <div style={{"marginTop": "25px"}}>

               <Button variant="contained" onClick={handleShowCsvAdd}>Add from CSV</Button> {/* add button to switch to CSV add screen */}
               <Button variant="contained" onClick={handleShowSingleAdd}>Add Single Question</Button> {/* add button to switch to single add screen */}
</div>
    </div>
  )
}

