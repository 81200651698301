import PropTypes from 'prop-types';
// @mui
import { useState, useEffect } from 'react';
import axios from 'axios';
import { alpha, styled } from '@mui/material/styles';
import { Box, Link, Card, Grid, Avatar, Typography, CardContent } from '@mui/material';
// utils
import { fDate } from '../../../utils/formatTime';
import { fShortenNumber } from '../../../utils/formatNumber';
//
import SvgColor from '../../../components/svg-color';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledCardMedia = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)',
});

const StyledTitle = styled(Link)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2),
}));

const StyledInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(3),
  color: theme.palette.text.disabled,
}));

const StyledCover = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

BlogPostCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function BlogPostCard({ post, index }) {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const { id, name, path, createdAt } = post;
  const latestPostLarge = index === 100;
  const latestPost = index === 100 || index === 200;

  const POST_INFO = [

    { number: 19, icon: 'eva:eye-fill' },

  ];

// Open the Pdf
const openPdf = (fileName) => {
  console.log(fileName);
  const url = `${process.env.REACT_APP_API_URL}/read?fileName=${fileName}`;

  fetch(url,  {
    headers: {
 "x-access-token": sessionStorage.getItem("x-access-token")
}
 
} )
    .then((response) => response.blob())
    .then((blob) => {
      const objectUrl = URL.createObjectURL(blob);
      window.open(objectUrl, '_blank');
    });
};

  // Download the pdfs 

  const handleDownloadClick = async (fileName) => {
    setLoading(true);
    try {
      // const fileName = selectedFile.fileName.split('/').pop();
     const response = await axios.get(`${process.env.REACT_APP_API_URL}/download/${fileName}`, {
      headers: {
   "x-access-token": sessionStorage.getItem("x-access-token")
 },
 responseType: 'blob',
}
      
      );
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'CCC.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
      setLoading(false);
    }
  };

  function NavigateToDetail() {
    // clicking on card
    // console.log("navigate working")
  }

  

  return (
    <Grid item xs={12} sm={latestPostLarge ? 12 : 6} md={latestPostLarge ? 6 : 3}>
      <Card onClick={() => NavigateToDetail()}  sx={{
   position: 'relative',
   ...(sessionStorage.getItem('plan') === 'Free' && {
     filter: index === 0 ? 'none' : 'blur(4px)',
     pointerEvents: index === 0 ? 'auto' : 'none',
   }),
 
  }}>
        <StyledCardMedia
        
          sx={{
            ...((latestPostLarge || latestPost) && {
              pt: 'calc(100% * 4 / 3)',
              '&:after': {
                top: 0,
                content: "''",
                width: '100%',
                height: '100%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
              },
            }),
            ...(latestPostLarge && {
              pt: {
                xs: 'calc(100% * 4 / 3)',
                sm: 'calc(100% * 3 / 4.66)',
              },
            }),
          }}
        >
          <SvgColor
            color="paper"
            src="/assets/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute',
              color: 'background.paper',
              ...((latestPostLarge || latestPost) && { display: 'none' }),
            }}
          />
          <StyledAvatar
            alt={"name"}
            src={''}
            sx={{
              ...((latestPostLarge || latestPost) && {
                zIndex: 9,
                top: 24,
                left: 24,
                width: 40,
                height: 40,
              }),
            }}
          />

          <StyledCover alt={"title"} src={'https://alltopstartups.com/wp-content/uploads/2020/01/What-Is-a-PDF-File-And-What-Are-PDFs-For.png'} />
        </StyledCardMedia>

        <CardContent
          sx={{
            pt: 4,
            ...((latestPostLarge || latestPost) && {
              bottom: 0,
              width: '100%',
              position: 'absolute',
            }),
          }}
        >
          {/* <Typography gutterBottom variant="caption" sx={{ color: 'text.disabled', display: 'block' }}>
            {fDate(createdAt)}
          </Typography> */}

          <StyledTitle
            color="inherit"
            variant="subtitle2"
            underline="hover"
            sx={{
              ...(latestPostLarge && { typography: 'h5', height: 60 }),
              ...((latestPostLarge || latestPost) && {
                color: 'common.white',
              }),
            }}
          >
           {name}
          </StyledTitle>

          <StyledInfo>
            {/* {POST_INFO.map((info, index) => ( */}
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  ml: index === 0 ? 0 : 1.5,
                  ...((latestPostLarge || latestPost) && {
                    color: 'grey.500',
                  }),
                }}
              >
                <Iconify icon={"info.icon"} sx={{ width: 16, height: 16, mr: 0.5 }} />
                <Typography variant="contained" onClick ={()=>openPdf(name)}>View &nbsp;&nbsp;&nbsp;&nbsp;</Typography>
                <Typography variant="contained" onClick = {()=>handleDownloadClick(name)}>Download</Typography>
              </Box>
            {/* ))} */}
          </StyledInfo>
        </CardContent>
      </Card>
    </Grid>
  );
}
