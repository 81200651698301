import React from 'react'

const Refund = () => {
  return (
    <div style={{ padding: "3%"}}>
 <h1>Refund & Cancellation Policy</h1>
  <p>Thank you for choosing our service. Please read our refund and cancellation policy below.</p>
  <h2>Refund Policy</h2>
  <p>If you have purchased our plan for 29 rupees, please note that this amount is non-refundable. Once the plan is purchased, no refund will be provided.</p>
  <h2>Cancellation Policy</h2>
  <p>We do not accept cancellations for the plan purchased as the amount is non-refundable.</p>

    </div>
  )
}

export default Refund