import { Helmet } from 'react-helmet-async';
// @mui
import axios from 'axios';
import {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import { Grid, Button, Container, Stack, Typography, CircularProgress } from '@mui/material';
// components
import Iconify from '../components/iconify';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../sections/@dashboard/blog';
// mock
import POSTS from '../_mock/blog';

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' },
];

// ----------------------------------------------------------------------

export default function BlogPage() {




  // start the backend code here 
  const [Pdfs, setPdfs] = useState([]);
  const [loading, setLoading] = useState(true);
  // my function 
  useEffect(()=>{
    const Data = async() => {
    
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/PdfsList`,
          {
               headers: {
            "x-access-token": sessionStorage.getItem("x-access-token")
          }
            
        } 
        
        ).then((res) => {
          

            if (res.data.rc >= 0) {

      setPdfs([...res.data.pdfs])
     
          // setIsLoaded(true)

            }
            else {
              // console.log(res.data.rc)
      
            }
          })
          setLoading(false)
        }
        catch (err) {
      setLoading(false)
          console.log(err)
        }
       
      };
      setTimeout(() => {
            Data();

      }, 500);
  },[])




  const navigate = useNavigate();
  const AddPdf= async(event)=>{
    navigate('/uploadpdf', { replace: true },
    { value: true });
  }
  return (
    <>
      <Helmet>
        <title> Dashboard: Study Material </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            PDFs
          </Typography>
          {sessionStorage.getItem('admin') === 'true' && 
           <Button variant="contained" onClick={()=>AddPdf()} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Post
          </Button>}
         
        </Stack>

        {/* <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
          <BlogPostsSearch posts={POSTS} />
          <BlogPostsSort options={SORT_OPTIONS} />
        </Stack> */}
      {loading ? <div style={{display: "flex", justifyContent: "center",padding: "25%", textAlign: "center"}}><CircularProgress/> </div>  : 
        <Grid container spacing={3}>
          
          {Pdfs.map((post, index) => (
// {sessionStorage.getItem('plan') === 'free' ? return (<div>hello</div>) : <div>nothing</div> }
            <BlogPostCard key={post.id} post={post} index={index} />
          
          ))}
        </Grid>}
      </Container>
    </>
  );
}
